import { ContainerModule } from 'inversify';

import { CheckYourEmailAdapter } from 'application/modules/auth/useCases/hooks/useCaseCheckYourEmail';
import { CreateAccountAdapter } from 'application/modules/auth/useCases/hooks/useCaseCreateAccount';
import { SignInAdapter } from 'application/modules/auth/useCases/hooks/useCaseSignIn';
import { VerifyUserAdapter } from 'application/modules/auth/useCases/hooks/useCaseVerifyUser';
import { AppStartupAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseAppStartup';
import { ChooseATimeAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseChooseATimeForLesson';
import { SessionLengthAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseChooseSessionLength';
import { ConfirmReservationAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseConfirmReservation';
import { GetFoodItemList } from 'application/modules/bookingWizard/useCases/hooks/useCaseGetFoodItemList';
import { GetSimulatorLocationsAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseGetSimulatorLocations';
import { PaymentDetailsAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCasePaymentDetails';
import { PreOrderFoodAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCasePreOrderFood';
import { ReservationCompleteAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseReservationComplete';
import { SelectClubFittingAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectClubFitting';
import { SelectExperienceAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectExperience';
import { GetInstructorsAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectInstructor';
import { GetLessonAvailabilityAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectLessonReservationDate';
import { GetSimulatorAvailabilityAdapter } from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectSimulatorReservationDate';
import { IManageBookingRepository } from 'domain/repositories/ManageBookingsRepository';
import ManageBookingRepository from 'infrastructure/repositories/ManageBookingRepository';

import ConnectedCheckYourEmailAdapter from './adapters/auth/CheckYourEmailAdapter';
import ConnectedCreateAccountAdapter from './adapters/auth/CreateAccountAdapter';
import ConnectedSignInAdapter from './adapters/auth/SignInAdapter';
import ConnectedVerifyUserAdapter from './adapters/auth/VerifyUserAdapter';
import ConnectedAppStartup from './adapters/bookingWizard/AppStartupAdapter';
import ConnectedChooseATimeAdapter from './adapters/bookingWizard/ChooseATimeAdapter';
import ConnectedConfirmReservationAdapter from './adapters/bookingWizard/ConfirmReservationAdapter';
import ConnectedGetFoodItemListAdapter from './adapters/bookingWizard/GetFoodItemListAdapter';
import ConnectedGetLessonAvailabilityAdapter from './adapters/bookingWizard/GetLessonAvailabilityAdapter';
import ConnectedGetSimulatorAvailabilityAdapter from './adapters/bookingWizard/GetSimulatorAvailabilityAdapter';
import ConnectedGetSimulatorLocationsAdapter from './adapters/bookingWizard/GetSimulatorLocationsAdapter';
import ConnectedPaymentDetailsAdapter from './adapters/bookingWizard/PaymentDetailsAdapter';
import ConnectedPreOrderFoodAdapter from './adapters/bookingWizard/PreOrderFoodAdapter';
import ConnectedReservationComplete from './adapters/bookingWizard/ReservationCompleteAdapter';
import ConnectedSelectClubFittingAdapter from './adapters/bookingWizard/selectClubFittingAdapter';
import ConnectedSelectExperienceAdapter from './adapters/bookingWizard/SelectExperienceAdapter';
import ConnectedSelectInstructor from './adapters/bookingWizard/SelectInstructorAdapter';
import ConnectedSessionLengthAdapter from './adapters/bookingWizard/SessionLengthAdapter';

const reduxContainerModule = new ContainerModule((bind) => {
  bind(GetSimulatorLocationsAdapter).toFunction(ConnectedGetSimulatorLocationsAdapter);
  bind(GetSimulatorAvailabilityAdapter).toFunction(ConnectedGetSimulatorAvailabilityAdapter);
  bind(GetLessonAvailabilityAdapter).toFunction(ConnectedGetLessonAvailabilityAdapter);
  bind(SignInAdapter).toFunction(ConnectedSignInAdapter);
  bind(CheckYourEmailAdapter).toFunction(ConnectedCheckYourEmailAdapter);
  bind(CreateAccountAdapter).toFunction(ConnectedCreateAccountAdapter);
  bind(PaymentDetailsAdapter).toFunction(ConnectedPaymentDetailsAdapter);
  bind(SessionLengthAdapter).toFunction(ConnectedSessionLengthAdapter);
  bind(ConfirmReservationAdapter).toFunction(ConnectedConfirmReservationAdapter);
  bind(VerifyUserAdapter).toFunction(ConnectedVerifyUserAdapter);
  bind(ReservationCompleteAdapter).toFunction(ConnectedReservationComplete);
  bind(AppStartupAdapter).toFunction(ConnectedAppStartup);
  bind(GetInstructorsAdapter).toFunction(ConnectedSelectInstructor);
  bind(ChooseATimeAdapter).toFunction(ConnectedChooseATimeAdapter);
  bind(SelectExperienceAdapter).toFunction(ConnectedSelectExperienceAdapter);
  bind(GetFoodItemList).toFunction(ConnectedGetFoodItemListAdapter);
  bind(PreOrderFoodAdapter).toFunction(ConnectedPreOrderFoodAdapter);
  bind(IManageBookingRepository).to(ManageBookingRepository).inSingletonScope();
  bind(SelectClubFittingAdapter).toFunction(ConnectedSelectClubFittingAdapter);
});

export default reduxContainerModule;
