import { useCallback } from 'react';

import mboAPI from 'infrastructure/redux/adapters/mboAPI/mboApi';

import { GTMEvent } from './GTMEvent';

const commonPayload = {
  non_personalized_ads: false,
};

const hasParams = (data: GTMEvent): data is GTMEvent & { params: unknown } => 'params' in data;

export const useGtmEvent = () => {
  const [sendGtmEventRequest, { isLoading, data, error }] = mboAPI.useSendGtmEventMutation();

  const sendGtmEvent = useCallback(
    (data: GTMEvent) => {
      const processedData = hasParams(data)
        ? { ...data, params: JSON.stringify(data.params) }
        : data;
      return sendGtmEventRequest({ ...commonPayload, ...processedData });
    },
    [sendGtmEventRequest],
  );

  return { isLoading, data, error, sendGtmEvent };
};
