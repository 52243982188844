import { Box, Chip, Typography } from '@mui/material';
import { formatDuration } from 'date-fns';
import React, { FC } from 'react';

import useCaseSelectClubFitting from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectClubFitting';
import { WizardStep } from 'domain/entities/WizardStep';
import HorizontalCard, { HorizontalCardType } from 'infrastructure/components/HorizontalCard';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import {
  selectLocation,
  selectLocationDetails,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import LocationSelect from 'infrastructure/targets/web/modules/bookingWizard/components/LocationSelect';
import { getFittingTypeImage } from 'infrastructure/targets/web/modules/common/imageUrlHelpers';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';

import { formatPhoneNumber } from '../../../common/helpers';
import { StyledContentContainer, StyledTitleContainer } from './style';

interface ISelectFittingTypesStep {
  stepName: WizardStep;
}
const SelectFittingTypesStep: FC<ISelectFittingTypesStep> = ({ stepName }) => {
  const t = useTranslationPrefix('book.select_fitting_type');
  const selectedLocationId = useAppSelector(selectLocation);
  const { clubFittingTypes, prevStep, nextStep } = useCaseSelectClubFitting(stepName);
  const selectedLocationDetails = useAppSelector(selectLocationDetails);
  const phoneNumber = selectedLocationDetails?.telephone;
  return (
    <PageLayout pageTitle={t('heading')} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep}>
        <LocationSelect selectedSimLocationId={selectedLocationId} />
      </NavigationBar>
      <StyledContentContainer>
        <StyledTitleContainer>
          <Typography variant="h3">{t('heading')}</Typography>
        </StyledTitleContainer>

        {clubFittingTypes?.length === 0 && (
          <Box padding={6}>
            <Typography variant="h5" textAlign="center">
              {`Club fitting is not currently available. Please select another location or call
              ${formatPhoneNumber(phoneNumber)} to get more information.`}
            </Typography>
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {clubFittingTypes?.map((el) => (
            <HorizontalCard
              key={el.id}
              variant={HorizontalCardType.Small}
              title={el.name}
              noBottomMargin
              handleCTA={() => nextStep(el)}
              ctaContent={t('reserve_now')}
              content={
                <Chip
                  variant="filled"
                  size="small"
                  color="default"
                  label={formatDuration(
                    { hours: Number(el.duration) / 60 },
                    { format: ['hours'], zero: true },
                  )}
                  sx={{ width: '72px' }}
                />
              }
              imageUrl={getFittingTypeImage(el.name)}
              imageSx={{
                backgroundColor: '#0000001A',
              }}
            />
          ))}
        </Box>
      </StyledContentContainer>
    </PageLayout>
  );
};

export default SelectFittingTypesStep;
