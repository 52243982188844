import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { StoreTypes, Variants } from 'common';
import { BookingWizardFlowTypes } from 'common/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import { format, formatDuration } from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useEffect, useMemo } from 'react';
import { object, string } from 'yup';

import useCaseReservationComplete from 'application/modules/bookingWizard/useCases/hooks/useCaseReservationComplete';
import { WizardStep } from 'domain/entities/WizardStep';
import ContentCard from 'infrastructure/components/ContentCard';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import {
  selectBookingForm,
  selectLocationDetails,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { useAppSelector } from 'infrastructure/redux/store/hooks';
import CostSummaryContainer, {
  CostSummaryVariant,
} from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary/CostSummaryContainer';
import PreOrderFoodContainer from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary/PreOrderFoodContainer';
// eslint-disable-next-line max-len
import { selectPromoCodeData } from 'infrastructure/redux/slices/payment.selector';
import ReservationSummaryContainer from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary/ReservationSummaryContainer';
import { StyledSummaryDivider } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary/style';
import FormikInput from 'infrastructure/targets/web/modules/common/FormikInput';
import {
  getAdapterLocale,
  getCurrency,
  Region,
  RegionType,
} from 'infrastructure/targets/web/modules/common/helpers';
import useEventTracking, {
  EventType,
} from 'infrastructure/targets/web/modules/common/hooks/useEventTracking';

import { StyledSectionColumn } from './style';

interface IReservationCompleteStep {
  stepName: WizardStep;
}

export type DataLayer = {
  push: (data: object) => void;
};

declare global {
  const dataLayer: DataLayer | undefined;
}

const isUSA = Region === RegionType.USA;
const isAustralia = Region === RegionType.Australia;
const isFiveIron = import.meta.env.VITE_VARIANT === Variants.FIVEIRON;

const ReservationCompleteStep: FC<IReservationCompleteStep> = () => {
  const {
    instructor,
    reservationDate,
    startTime,
    sessionLength,
    leftHanded,
    clubRental,
    appointments,
    addOns,
    partySize,
    experienceType,
    endTime,
    bookingUUID,
    totalPrice,
  } = useAppSelector(selectBookingForm);
  const { addNextGuest, makeAnotherBooking, removeGuest, guests, isWaiverLoading, nextStep } =
    useCaseReservationComplete();
  const discountedTotal =
    totalPrice ?? appointments?.reduce((total, item) => Number(total) + Number(item.price), 0) ?? 0;
  const total = appointments?.reduce((total, item) => Number(total) + Number(item.price), 0) ?? 0;
  const isSmallEvent = partySize === '7-12';
  const locationDetails = useAppSelector(selectLocationDetails);
  const livePromoCodeData = useAppSelector(selectPromoCodeData);
  const isClubFitting = experienceType === BookingWizardFlowTypes.ClubFitting;
  const { sendEvent } = useEventTracking();
  const summaryItems = useMemo(() => {
    const baseItems = [
      {
        label: 'Location',
        value: locationDetails?.name,
      },
      {
        label: 'Date',
        value:
          reservationDate &&
          format(new Date(reservationDate), 'EEEE, P', {
            locale: getAdapterLocale(),
          }),
      },
      {
        label: 'Time',
        value: startTime && format(new Date(startTime.slice(0, -1)), 'hh:mm aa'),
      },
      {
        label: 'Session Length',
        value:
          sessionLength &&
          formatDuration({ hours: Number(sessionLength) }, { format: ['hours'], zero: true }),
      },
    ];

    if (isFiveIron) {
      baseItems.push({
        label: 'Left Handed Golfers',
        value: leftHanded ? 'Yes' : 'No',
      });
      if (!isClubFitting) {
        baseItems.push({
          label: 'Clubs Requested',
          value: clubRental ? 'Yes' : 'No',
        });
      }
    }

    return baseItems;
  }, [locationDetails, reservationDate, sessionLength, leftHanded, clubRental]);

  useEffect(() => {
    if (
      typeof dataLayer !== 'undefined' &&
      appointments?.length &&
      locationDetails &&
      startTime &&
      sessionLength &&
      reservationDate &&
      total
    ) {
      sendEvent(
        EventType.Purchase,
        {
          date: format(new Date(reservationDate as unknown as Date), 'P'),
          party_size: partySize,
          location: locationDetails?.name,
          session_start: startTime && format(new Date(startTime.slice(0, -1)), 'hh:mm aa'),
          session_duration: formatDuration(
            { hours: Number(sessionLength) },
            { format: ['hours'], zero: true },
          ),
          value: total,
          currency: getCurrency(),
          coupon: livePromoCodeData?.code || null,
          payment_type: 'Credit Card',
          payment_method: 'n/a',
          checkout_section: 'Step 1: Shipping Method Completed',
          utm: localStorage.getItem('utm') ? JSON.parse(localStorage.getItem('utm')) : null,
        },
        [],
        {
          id: bookingUUID,
          experienceType,
          location: locationDetails.id,
          reservationDate,
          partySize,
          startTime,
          endTime,
          addOns,
          instructor,
          sessionLength,
          appointments,
        },
      );
    }
  }, []);

  const preOrderAvailable = () => {
    if (!locationDetails?.squareId) return false;
    switch (locationDetails?.foodOrderAvailability) {
      case StoreTypes.FoodOrderAvailability.NotAvailable:
        return false;
      case StoreTypes.FoodOrderAvailability.All:
        return true;
      case StoreTypes.FoodOrderAvailability.PartyOnly:
        return partySize === '7-12';
      default:
        return false;
    }
  };
  const schemaObject = Object.fromEntries(
    guests.map((guest) => [guest.name, string().email('Please provide valid email')]),
  );

  const initialValues = useMemo(
    () => Object.fromEntries(guests.map((guest) => [guest.name, guest.email || ''])),
    [],
  );

  const validationSchema = object().shape(schemaObject);

  const reservationCompleteForm = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      nextStep(values);
    },
  });

  return (
    <PageLayout pageTitle={isAustralia ? 'Reservation Confirmed' : 'Sign Waiver'} shouldFadeIn>
      <StyledSectionColumn>
        <NavigationBar noBackButton></NavigationBar>
        <ContentCard title={'See You Soon!'}>
          <FormikProvider value={reservationCompleteForm}>
            <Typography variant="h5" sx={{ mt: 3 }}>
              Reservation Summary
            </Typography>
            <ReservationSummaryContainer summaryItems={summaryItems} />
            <Typography variant="h5">Cost Summary</Typography>
            <CostSummaryContainer
              pricing={appointments}
              variant={CostSummaryVariant.ReservationComplete}
              total={discountedTotal}
              addOns={addOns}
              isSmallEvent={isSmallEvent}
              sessionLength={sessionLength}
              discountAmount={total - discountedTotal}
            />
            <StyledSummaryDivider />
            {isUSA && preOrderAvailable() && <PreOrderFoodContainer></PreOrderFoodContainer>}
            {!isAustralia && !isClubFitting && (
              <>
                <Typography variant="h2" sx={{ mb: 3, mt: 3 }}>
                  Sign Waiver
                </Typography>
                <Typography variant="body1">
                  Save time on arrival by sharing a link to the waiver with appointment details with
                  your guests:
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%',
                  }}>
                  {guests?.map((guest, idx) => (
                    <FormikInput
                      variant="outlined"
                      key={guest?.name}
                      color="secondary"
                      type="email"
                      name={guest.name || ''}
                      noFormLabel
                      label={`Email Address Guest ${idx + 1}`}
                      sx={{ my: 4, width: '100%' }}
                      InputProps={{
                        endAdornment: idx > 0 && (
                          <InputAdornment position="end">
                            <IconButton onClick={() => removeGuest(guest.name)} edge="end">
                              <DeleteOutlined />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ))}

                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    startIcon={<AddOutlined />}
                    onClick={addNextGuest}>
                    Add Next Guest
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  className="nextButton"
                  size="large"
                  sx={{ mt: 6 }}
                  onClick={() => reservationCompleteForm.handleSubmit()}>
                  {isWaiverLoading ? (
                    <CircularProgress sx={{ color: 'primary.contrastText' }} />
                  ) : (
                    'Send to Guests & Sign Your Waiver'
                  )}
                </Button>
              </>
            )}
            <Button
              variant={!isAustralia && !isClubFitting ? 'text' : 'contained'}
              color="primary"
              size="large"
              sx={{ mt: 3, mb: { xs: 5, sm: 0 } }}
              onClick={makeAnotherBooking}>
              Book Another Reservation
            </Button>
          </FormikProvider>
        </ContentCard>
      </StyledSectionColumn>
    </PageLayout>
  );
};

export default ReservationCompleteStep;
