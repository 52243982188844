import { AvailabilityTypes } from 'common';
import { BookingWizardFlowTypes } from 'common/dist/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import {
  ClubFittingAppointmentAvailability,
  SimulatorAppointmentAvailability,
} from 'common/infrastructure/modules/appointment/interfaces/AvailabilityTypes';
import { UserSummary } from 'common/infrastructure/modules/auth/interfaces/UserTypes';
import { useInjection } from 'inversify-react';
import { useEffect } from 'react';

import { WizardStep } from 'domain/entities/WizardStep';
import { AsyncHookResult, InjectableHook, useHookInjection } from 'domain/hooks';
import { SelectFittingStartTimeForm } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SelectFittingStartTimeStep';

import {
  BookingWizardNavigationHook,
  IBookingWizardNavigationHook,
} from './useCaseNavigateBookingWizard';

export enum SelectClubFittingError {
  Unknown = 'unknown',
  Unauthorized = 'unauthorized',
}

export const SelectClubFittingAdapter = Symbol('SelectClubFittingAdapter');

export type ISelectClubFittingAdapter = InjectableHook<
  AsyncHookResult<Array<ClubFittingAppointmentAvailability>, SelectClubFittingError> & {
    setFlowTypeToClubFitting: () => void;
    flowType: string | undefined;
    clearPartySize: () => void;
    SelectClubFitting: ({
      locationId,
      clubFittingId,
      startDateTime,
      endDateTime,
      email,
    }: {
      locationId: string;
      clubFittingId: number;
      startDateTime: string;
      endDateTime: string;
      email?: string;
    }) => void;

    SelectClubFittingType: (locationId: string) => void;
    clubFittingTypes: AvailabilityTypes.ClubFittingType[] | undefined;
    clubFittingTypesInProgress: boolean;
    clubFittingTypesError: any;
    userData: UserSummary;
    availableDates: string[] | undefined;
    availabilities: SimulatorAppointmentAvailability[];
    getAvailableLessonSlotButtons: () => any;
    saveSelectedClubFittingTypeToStore: (values: AvailabilityTypes.ClubFittingType) => void;
    saveAppointmentToStore: (values: SelectFittingStartTimeForm) => void;
    handleGetPricing: (values: any) => void;
    pricing: any;
  }
>;

const useCaseSelectClubFitting = (currentStep: WizardStep) => {
  const adapter = useInjection<ISelectClubFittingAdapter>(SelectClubFittingAdapter);

  // Always call the hook, even if you don't need it right away
  const bookingWizardNavigation = useHookInjection<IBookingWizardNavigationHook>(
    BookingWizardNavigationHook,
  );

  const {
    error,
    result,
    inProgress,
    clearPartySize,
    setFlowTypeToClubFitting,
    SelectClubFitting,
    flowType,
    SelectClubFittingType,
    saveSelectedClubFittingTypeToStore,
    clubFittingTypes,
    clubFittingTypesInProgress,
    clubFittingTypesError,
    userData,
    availableDates,
    availabilities,
    saveAppointmentToStore,
    getAvailableLessonSlotButtons,
    handleGetPricing,
    pricing,
  } = adapter();

  const handleNextStep = (values: any) => {
    clearPartySize();
    if (currentStep === WizardStep.selectFittingStartTime) {
      saveAppointmentToStore(values);
    } else if (currentStep === WizardStep.selectFittingType) {
      saveSelectedClubFittingTypeToStore(values);
    }
    if (!userData.creditCard && currentStep === WizardStep.selectFittingStartTime) {
      bookingWizardNavigation.redirectToStep(WizardStep.paymentDetails);
    } else {
      bookingWizardNavigation.redirectToNextStep(currentStep);
    }
  };

  useEffect(() => {
    if (
      (!flowType || flowType !== BookingWizardFlowTypes.ClubFitting) &&
      currentStep !== WizardStep.selectFittingStartTime
    ) {
      setFlowTypeToClubFitting();
    }
  }, []);

  return {
    inProgress,
    error,
    result,
    SelectClubFitting,
    nextStep: handleNextStep,
    prevStep: () => {
      if (bookingWizardNavigation && currentStep) {
        bookingWizardNavigation.redirectToPrevStep(currentStep);
      }
    },
    availableDates,
    availabilities,
    getAvailableLessonSlotButtons,
    getPricing: handleGetPricing,
    pricing: pricing.data,
    isPricingLoading: pricing.isLoading,
    SelectClubFittingType,
    clubFittingTypes,
    clubFittingTypesInProgress,
    clubFittingTypesError,
  };
};

export default useCaseSelectClubFitting;
