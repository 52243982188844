import { Variants } from 'common';

import { ReactComponent as MBOLogo } from 'infrastructure/targets/web/assets/icons/MindBodyLogo.svg';
import { ReactComponent as MBOLogoLight } from 'infrastructure/targets/web/assets/icons/mindbody-logo-light.svg';
import { isGenericDuckpin } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/helpers';

import { isAustralia } from './helpers';

const resolveImageVariant = () => process.env.VITE_VARIANT || Variants.FIVEIRON;

export const getLogoLink = () => {
  try {
    if (isAustralia()) {
      return 'https://fiveirongolf.au/';
    }

    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return 'https://fiveirongolf.com/';

      case Variants.DUCKPIN:
        return isGenericDuckpin()
          ? 'https://duckpin.fiveirongolf.com'
          : 'https://detroitduckpin.com';

      default:
        return 'https://fiveirongolf.com/';
    }
  } catch (error) {
    return 'https://fiveirongolf.com/';
  }
};

export const getInstructorPlaceholderImageUrl = () => {
  try {
    return new URL('infrastructure/targets/web/assets/icons/placeholderImage.png', import.meta.url)
      .href;
  } catch (error) {
    return '';
  }
};

export const getTopBannerPictureUrl = () => {
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/topBanner.jpg`,
          import.meta.url,
        ).href;
      case Variants.DUCKPIN:
        return new URL(
          'infrastructure/targets/web/assets/images/duckpin/topBanner.jpg',
          import.meta.url,
        ).href;
      default:
        return new URL(
          'infrastructure/targets/web/assets/images/fiveiron/topBanner.jpg',
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};

export const getSimThumbnail = () => {
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/simThumbnail.jpg`,
          import.meta.url,
        ).href;
      case Variants.DUCKPIN:
        return new URL(
          'infrastructure/targets/web/assets/images/duckpin/simThumbnail.jpg',
          import.meta.url,
        ).href;
      default:
        return new URL(
          'infrastructure/targets/web/assets/images/fiveiron/simThumbnail.jpg',
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};

export const getBookingImage = () => {
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/simBooking.jpg`,
          import.meta.url,
        ).href;
      case Variants.DUCKPIN:
        return new URL(
          'infrastructure/targets/web/assets/images/duckpin/simBooking.jpg',
          import.meta.url,
        ).href;
      default:
        return new URL(
          'infrastructure/targets/web/assets/images/fiveiron/simBooking.jpg',
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};

export const getSimBookingImage = () =>
  new URL(`infrastructure/targets/web/assets/images/fiveiron/simBooking.jpg`, import.meta.url).href;

export const getClubFittingImage = () =>
  new URL(`infrastructure/targets/web/assets/images/fiveiron/clubFitting.png`, import.meta.url)
    .href;

export const getBowlingBookingImage = () =>
  new URL('infrastructure/targets/web/assets/images/duckpin/simBooking.jpg', import.meta.url).href;

export const getPreOrderFoodImage = () =>
  new URL('infrastructure/targets/web/assets/images/fiveiron/preOrderFood.png', import.meta.url)
    .href;

export const getFoodImagePlaceholder = () =>
  new URL('infrastructure/targets/web/assets/images/fiveiron/logo.svg', import.meta.url).href;

export const getLessonBookingImage = () => {
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/lessonBooking.jpg`,
          import.meta.url,
        ).href;
      case Variants.DUCKPIN:
        return new URL(
          'infrastructure/targets/web/assets/images/duckpin/lessonBooking.jpg',
          import.meta.url,
        ).href;
      default:
        return new URL(
          'infrastructure/targets/web/assets/images/fiveiron/lessonBooking.jpg',
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};

export const getFittingTypeImage = (name: string) => {
  try {
    switch (name) {
      case 'Driver Fitting':
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/driver_fitting.png`,
          import.meta.url,
        ).href;
      case 'Fairway Woods/Hybrid Fitting':
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/fairway_hybrid_fitting.png`,
          import.meta.url,
        ).href;
      case 'Iron Fitting':
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/iron_fitting.png`,
          import.meta.url,
        ).href;
      case 'Wedge Fitting':
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/wedge_fitting.png`,
          import.meta.url,
        ).href;
      case 'Putter Fitting':
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/putter_fitting.png`,
          import.meta.url,
        ).href;
      case 'Golf Ball Fitting':
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/golfball_fitting.png`,
          import.meta.url,
        ).href;
      case 'Long Game Fitting':
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/long_game_fitting.png`,
          import.meta.url,
        ).href;
      case 'Short Game Fitting':
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/short_game_fitting.png`,
          import.meta.url,
        ).href;
      default:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/driver_fitting.png`,
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};

export const getMainLogo = () => {
  const duckpinGeneric = isGenericDuckpin();
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/logo.png`,
          import.meta.url,
        ).href;
      case Variants.DUCKPIN:
        return duckpinGeneric
          ? new URL(`infrastructure/targets/web/assets/images/fiveiron/logo.png`, import.meta.url)
              .href
          : new URL('infrastructure/targets/web/assets/images/duckpin/logo.png', import.meta.url)
              .href;
      default:
        return new URL(
          'infrastructure/targets/web/assets/images/fiveiron/logo.png',
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};

export const getMindBodyLogo = () => {
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return MBOLogo;
      case Variants.DUCKPIN:
        return MBOLogoLight;
      default:
        return MBOLogo;
    }
  } catch (error) {
    return MBOLogo;
  }
};

export const getMobileLogo = () => {
  try {
    switch (resolveImageVariant()) {
      case Variants.FIVEIRON:
        return new URL(
          `infrastructure/targets/web/assets/images/fiveiron/logo.png`,
          import.meta.url,
        ).href;
      case Variants.DUCKPIN:
        return new URL(
          'infrastructure/targets/web/assets/images/duckpin/logoMobile.png',
          import.meta.url,
        ).href;
      default:
        return new URL(
          'infrastructure/targets/web/assets/images/fiveiron/logo.png',
          import.meta.url,
        ).href;
    }
  } catch (error) {
    return '';
  }
};
