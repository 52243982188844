import { AccessTimeOutlined } from '@mui/icons-material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Box, Fade, Typography } from '@mui/material';
import { format, isBefore } from 'date-fns';
import { useFormik } from 'formik';
import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { number, object, string } from 'yup';

import { formatPricing } from 'application/modules/bookingWizard/common/helpers';
import useCaseSelectClubFitting from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectClubFitting';
import useCaseSelectLessonReservationDate from 'application/modules/bookingWizard/useCases/hooks/useCaseSelectLessonReservationDate';
import { WizardStep } from 'domain/entities/WizardStep';
import BookingCalendar from 'infrastructure/components/BookingCalendar';
import ButtonsGroup from 'infrastructure/components/ButtonsGroup';
import HorizontalCard, { HorizontalCardType } from 'infrastructure/components/HorizontalCard';
import Loader from 'infrastructure/components/Loader';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import SectionTitle from 'infrastructure/components/SectionTitle';
import {
  selectClubFittingType,
  selectLocation,
  selectLocationDetails,
  selectReservationDate,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { setReservationDate } from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import { ISessionSummary } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep';
import ReservationSummary from 'infrastructure/targets/web/modules/bookingWizard/Steps/SessionLengthStep/components/ReservationSummary';
import useEventTracking, {
  EventType,
} from 'infrastructure/targets/web/modules/common/hooks/useEventTracking';
import { getFittingTypeImage } from 'infrastructure/targets/web/modules/common/imageUrlHelpers';

import { formatPhoneNumber } from '../../../common/helpers';
import LocationSelect from '../../components/LocationSelect';
import {
  StyledAsideContainer,
  StyledButtonsContainer,
  StyledCalendarContainer,
  StyledContainer,
  StyledFormContainer,
  StyledLeftContainer,
  StyledLoaderContainer,
} from './style';

interface ISelectFittingStartTimeStep {
  stepName: WizardStep;
}

export interface SelectFittingStartTimeForm {
  reservationDate: string;
  sessionHour: string;
  resourceId: number | undefined;
  staffId: number | undefined;
}

const TEMP_BLOCK_DATE = new Date('2025-01-17T08:00:00z');

// TODO (davlis): Finish
const SelectFittingStartTimeStep: FC<ISelectFittingStartTimeStep> = ({ stepName }) => {
  const { sendEvent } = useEventTracking();
  const today = useRef(new Date());
  const [sessionSummary, setSessionSummary] = useState<ISessionSummary>();
  const [sessionHour, setSessionHour] = useState<string>('');

  const locationDetails = useAppSelector(selectLocationDetails);
  const selectedLocationId = useAppSelector(selectLocation);
  const clubFittingType = useAppSelector(selectClubFittingType);
  const selectedReservationDate = useAppSelector(selectReservationDate);
  const selectedLocationDetails = useAppSelector(selectLocationDetails);
  const phoneNumber = selectedLocationDetails?.telephone;
  const {
    result,
    inProgress,
    error,
    availabilities,
    getAvailableLessonSlotButtons,
    getPricing,
    pricing,
    isPricingLoading,
    prevStep,
    nextStep,
  } = useCaseSelectClubFitting(stepName);

  const { result: availableDates, fetchFurtherAvailableDates } =
    useCaseSelectLessonReservationDate();

  const dispatch = useAppDispatch();
  const validationSchema = useMemo(
    () =>
      object().shape({
        reservationDate: string()
          .required()
          .test(
            'empty-check',
            'Please select a date',
            (reservationDate) => reservationDate?.length !== 0,
          ),
        sessionHour: string().required(),
        staffId: number().required(),
        resourceId: number().required(),
      }),
    [],
  );

  const filteredAvailableDates = useMemo(
    () => (availableDates ? availableDates.filter((date) => date >= TEMP_BLOCK_DATE) : []), // Temp fix for blocking dates pre clubFitting launch
    [availableDates],
  );

  const reservationForm = useFormik<SelectFittingStartTimeForm>({
    initialValues: {
      reservationDate: `${
        isBefore(today.current, TEMP_BLOCK_DATE) ? TEMP_BLOCK_DATE : today.current
      }`,
      sessionHour: '',
      resourceId: undefined,
      staffId: undefined,
    },
    validationSchema,
    validateOnBlur: false,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      nextStep(values);
      sendEvent(EventType.SelectFittingStartTime, {
        location: locationDetails?.name,
        reservation_date: format(new Date(values.reservationDate), 'P'),
        session_hour: values.sessionHour,
        resource_id: values.resourceId,
        staff_id: values.staffId,
        utm: localStorage.getItem('utm') ? JSON.parse(localStorage.getItem('utm') as string) : null,
      });
    },
  });

  useEffect(() => {
    dispatch(
      setReservationDate({
        reservationDate: `${
          isBefore(today.current, TEMP_BLOCK_DATE) ? TEMP_BLOCK_DATE : today.current
        }`,
      }),
    );
  }, []);

  useEffect(() => {
    setSessionSummary({
      sessionHour: sessionHour === 'unset' ? '' : sessionHour,
      session: '',
    });
  }, [sessionHour]);

  useEffect(() => {
    if (
      reservationForm.isValid &&
      reservationForm.values.reservationDate &&
      reservationForm.values.resourceId &&
      reservationForm.values.sessionHour &&
      reservationForm.values.staffId
    ) {
      getPricing(reservationForm.values);
    }
  }, [reservationForm.values, reservationForm.isValid]);

  const formattedPricing = formatPricing(pricing);
  const handleDateChange = (value: Date | null) => {
    if (!value) return;

    dispatch(setReservationDate({ reservationDate: `${value}` }));
    reservationForm.setFieldValue('reservationDate', value);
    reservationForm.setTouched({ reservationDate: true });
  };

  const debouncedSimDateFetch = useDebouncedCallback((value) => {
    fetchFurtherAvailableDates(value);
  }, 300);

  const handleMonthChange = (value: Date | null) => {
    debouncedSimDateFetch(value);
  };

  const availableLessonSlots = useCallback(() => {
    if (!inProgress) {
      return getAvailableLessonSlotButtons();
    }
  }, [inProgress, availabilities]);

  const handleSessionHourChange = async (value: string, event: MouseEvent<HTMLElement>) => {
    const selectedResourceId = event?.currentTarget.dataset.secondaryvalue;
    setSessionHour(value);
    await reservationForm.setFieldValue('sessionHour', value);
    await reservationForm.setFieldValue('resourceId', selectedResourceId);
    await reservationForm.setFieldValue('staffId', result?.[0]?.staffId);
  };

  return (
    <PageLayout pageTitle={'Select a start time'} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep}>
        <LocationSelect selectedSimLocationId={selectedLocationId} />
      </NavigationBar>
      <StyledContainer>
        <StyledLeftContainer>
          <Box sx={{ mb: 6 }}>
            <HorizontalCard
              variant={HorizontalCardType.Large}
              title={clubFittingType?.name ?? 'Club Fitting'}
              content={
                <>
                  <Typography variant={'body1'}>
                    <strong>What’s included?</strong>
                  </Typography>
                  <Typography variant={'body2'}>
                    {
                      'Elevate your equipment with a personalized session guided by Five Iron’s expert fitters.'
                    }
                    <br />
                    {
                      // eslint-disable-next-line max-len
                      'Using Trackman technology and Callaway’s premium equipment, our team will analyze your performance to recommend the perfect clubs to optimize your game.'
                    }
                    <br />
                  </Typography>
                </>
              }
              imageUrl={getFittingTypeImage(clubFittingType?.name ?? '')}
            />
          </Box>
          <StyledFormContainer component="form">
            <SectionTitle
              title={'Date'}
              subtitle={'Select a date for your next lesson with a 5i instructor.'}
              icon={<CalendarMonthOutlinedIcon />}
            />
            <StyledCalendarContainer>
              <Fade in={inProgress} timeout={400}>
                <StyledLoaderContainer>
                  <Loader />
                  <Typography variant="body2" color="text.secondary">
                    Fetching available days
                  </Typography>
                </StyledLoaderContainer>
              </Fade>
              <BookingCalendar
                availableDates={filteredAvailableDates}
                selectedValue={selectedReservationDate}
                onChange={handleDateChange}
                onMonthChange={handleMonthChange}
              />
            </StyledCalendarContainer>

            <SectionTitle title={'Start Time'} icon={<AccessTimeOutlined />} />
            <StyledButtonsContainer>
              {error && !inProgress && (
                <StyledLoaderContainer>
                  <Typography variant="body2">
                    Sorry, but there was an error while fetching available appointments, please try
                    again later
                  </Typography>
                </StyledLoaderContainer>
              )}
              {inProgress && !error ? (
                <StyledLoaderContainer>
                  <Loader />
                  <Typography variant="body2">Fetching available appointments</Typography>
                </StyledLoaderContainer>
              ) : availabilities?.length === 0 ? (
                <Box padding={6}>
                  <Typography variant="h5" textAlign="center">
                    {`No fitters currently available, please select another date or call ${formatPhoneNumber(
                      phoneNumber,
                    )} to get more information.`}
                  </Typography>
                </Box>
              ) : (
                <ButtonsGroup
                  buttonList={availableLessonSlots()}
                  selectedValue={sessionHour}
                  onChange={handleSessionHourChange}
                />
              )}
            </StyledButtonsContainer>
          </StyledFormContainer>
        </StyledLeftContainer>
        <StyledAsideContainer>
          <ReservationSummary
            sessionSummary={sessionSummary}
            isFormValid={reservationForm.isValid}
            onSubmit={() => reservationForm.handleSubmit()}
            pricing={formattedPricing.appointments}
            showPricing={formattedPricing.appointments.length > 0}
            isLoading={inProgress || isPricingLoading}
          />
        </StyledAsideContainer>
      </StyledContainer>
    </PageLayout>
  );
};
export default SelectFittingStartTimeStep;
