import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { object, string } from 'yup';
import { BookingWizardFlowTypes } from 'common/infrastructure/modules/appointment/interfaces/AppointmentTypes';
import { format, formatDuration } from 'date-fns';

import useCaseCreateAccount, {
  CreateAccountError,
} from 'application/modules/auth/useCases/hooks/useCaseCreateAccount';
import { WizardStep } from 'domain/entities/WizardStep';
import AppURLs from 'infrastructure/common/AppURLs';
import ContentCard from 'infrastructure/components/ContentCard';
import NavigationBar from 'infrastructure/components/NavigationBar';
import PageLayout from 'infrastructure/components/PageLayout';
import {
  selectBookingForm,
  selectEmail,
  selectExperienceType,
  selectLocation,
  selectLocationDetails,
} from 'infrastructure/redux/slices/bookingWizard.selector';
import { setLocation } from 'infrastructure/redux/slices/bookingWizard.slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux/store/hooks';
import LocationSelect from 'infrastructure/targets/web/modules/bookingWizard/components/LocationSelect';
import FormikInput from 'infrastructure/targets/web/modules/common/FormikInput';
import { StyledSection500Column } from 'infrastructure/targets/web/modules/bookingWizard/Steps/SignInStep/style';
import PhoneNumberInput from 'infrastructure/targets/web/modules/bookingWizard/components/PhoneNumberInput';
import useEventTracking, {
  EventType,
} from 'infrastructure/targets/web/modules/common/hooks/useEventTracking';
import {
  getCurrency,
  mapExperienceTypeToItemDetails,
} from 'infrastructure/targets/web/modules/common/helpers';
import {
  selectPromoCodeData,
  selectTotalAmountToPay,
} from 'infrastructure/redux/slices/payment.selector';

import { StyledStrongLink } from './style';

interface ICreateAccountStep {
  stepName: WizardStep;
}

interface ICreateAccountForm {
  email: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  mobilephone: string;
  location: string;
}

const isUAE = import.meta.env.VITE_SENTRY_ENV.endsWith('_ae');
const isAustralia = import.meta.env.VITE_SENTRY_ENV.endsWith('_au');

const CreateAccountStep: FC<ICreateAccountStep> = () => {
  const theme = useTheme();
  const { nextStep, prevStep, handleNavigateToSignIn, error, inProgress } = useCaseCreateAccount();
  const dispatch = useAppDispatch();
  const selectedLocationId = useAppSelector(selectLocation);
  const selectedEmail = useAppSelector(selectEmail);
  const experienceType = useAppSelector(selectExperienceType);
  const { reservationDate, startTime, sessionLength, partySize } =
    useAppSelector(selectBookingForm);
  const total = useAppSelector(selectTotalAmountToPay);
  const locationDetails = useAppSelector(selectLocationDetails);
  const livePromoCodeData = useAppSelector(selectPromoCodeData);
  const { sendEvent } = useEventTracking();

  const validationSchema = object().shape({
    email: string().email('Invalid email').required('Required'),
    mobilephone: string().min(9, 'Phone number is not valid').required('false'),
    firstName: string().required('Required').min(2, 'First name must be at least 2 characters'),
    lastName: string().required('Required').min(2, 'Last name must be at least 2 characters'),
    postalCode: isUAE
      ? string().notRequired()
      : string().required('Required').min(3, 'Postal code has to be at least 3 characters'),
  });

  useEffect(() => {
    if (error) {
      switch (error) {
        case CreateAccountError.ClientAlreadyExists:
          createAccountForm.setErrors({
            email: 'A user with this email already exists, please sign in.',
          });
          break;
        case CreateAccountError.Error:
          createAccountForm.setErrors({
            email: 'Something went wrong. Please contact support',
          });
          break;
        case CreateAccountError.InvalidEmail:
          createAccountForm.setErrors({
            email: 'Please provide a valid email',
          });
          break;
        case CreateAccountError.Unknown:
          createAccountForm.setErrors({
            email: 'Something went wrong. Please contact support',
          });
          break;

        default:
          createAccountForm.setErrors({ location: error });
          break;
      }
    }
  }, [error]);

  const createAccountForm = useFormik<ICreateAccountForm>({
    initialValues: {
      email: selectedEmail || '',
      firstName: '',
      lastName: '',
      postalCode: '',
      mobilephone: '',
      location: selectedLocationId || '',
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      dispatch(setLocation(values.location));
      nextStep({ ...values, locationId: values.location });
      sendEvent(EventType.SignUp, {
        utm: localStorage.getItem('utm') ? JSON.parse(localStorage.getItem('utm')) : null,
      });
    },
  });

  useEffect(() => {
    if (!experienceType) {
      return;
    }
    sendEvent(
      EventType.BeginCheckout,
      {
        date: format(new Date(reservationDate as unknown as Date), 'P'),
        party_size: partySize,
        location: locationDetails?.name,
        session_start: startTime && format(new Date(startTime.slice(0, -1)), 'hh:mm aa'),
        session_duration: formatDuration(
          { hours: Number(sessionLength) },
          { format: ['hours'], zero: true },
        ),
        value: total,
        currency: getCurrency(),
        coupon: livePromoCodeData?.code || null,
        checkout_section: 'Step 0: Checkout Started',
        content_group: 'Checkout',
        utm: localStorage.getItem('utm') ? JSON.parse(localStorage.getItem('utm')) : null,
      },
      [
        {
          ...mapExperienceTypeToItemDetails(experienceType as BookingWizardFlowTypes),
          currency: getCurrency(),
          index: 0,
          location_id: 'Product Listing',
          price: total,
          quantity: 1,
        },
      ],
    );
  }, [experienceType]);

  return (
    <PageLayout pageTitle={'Check Out'} shouldFadeIn>
      <NavigationBar backButtonCallback={prevStep} />
      <StyledSection500Column>
        <FormikProvider value={createAccountForm}>
          <ContentCard title={'Get Started'}>
            <Typography variant={'body1'} sx={{ mt: 3, mb: 7 }}>
              Already have an account? {''}
              <StyledStrongLink onClick={handleNavigateToSignIn}>Sign In</StyledStrongLink>
            </Typography>
            <FormikInput
              variant="outlined"
              color="secondary"
              type="email"
              name="email"
              noFormLabel
              label={'Email Address'}
              sx={{ mb: 5, width: '100%' }}
            />
            <FormikInput
              variant="outlined"
              color="secondary"
              type="text"
              name="firstName"
              noFormLabel
              label={'First Name'}
              sx={{ mb: 5, width: '100%' }}
            />
            <FormikInput
              variant="outlined"
              color="secondary"
              type="text"
              name="lastName"
              noFormLabel
              label={'Last Name'}
              sx={{ mb: 5, width: '100%' }}
            />
            {!isUAE && (
              <FormikInput
                variant="outlined"
                color="secondary"
                type="text"
                name="postalCode"
                noFormLabel
                label={'Postal Code'}
                inputProps={{ maxLength: 15 }}
                sx={{ mb: 5, width: '100%' }}
              />
            )}
            <PhoneNumberInput
              variant="outlined"
              color="secondary"
              type="text"
              name="mobilephone"
              defaultCountry={isUAE ? 'ae' : isAustralia ? 'au' : 'us'}
              noFormLabel
              label={'Phone Number'}
              sx={{ mb: 5, width: '100%' }}
            />
            <Box sx={{ mb: 5, width: '100%' }}>
              <LocationSelect
                onChange={createAccountForm.handleChange}
                fullWidth={true}
                selectedSimLocationId={selectedLocationId}
                label="Preferred Location"
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              className="nextButton"
              size="large"
              onClick={() => createAccountForm.handleSubmit()}
              disabled={
                !createAccountForm.isValid ||
                (Object.keys(createAccountForm.touched).length === 0 &&
                  createAccountForm.touched.constructor === Object)
              }>
              {inProgress ? (
                <CircularProgress sx={{ color: 'primary.contrastText' }} />
              ) : (
                'Create Account'
              )}
            </Button>
            <Typography
              variant={'body1'}
              sx={{
                mt: { xs: 1, sm: 5 },
                width: '100%',
                textAlign: 'center',
                mb: { xs: 7, sm: 0 },
              }}>
              {'By signing up, I agree to '}
              <a
                href={AppURLs.termsOfUse}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                Terms of Use
              </a>
              {' and '}
              <a
                href={AppURLs.privacyPolicy}
                style={{ textDecoration: 'none', color: theme.palette.text.primary }}
                target="_blank"
                rel="noreferrer">
                Privacy Policy
              </a>
            </Typography>
          </ContentCard>
        </FormikProvider>
      </StyledSection500Column>
    </PageLayout>
  );
};

export default CreateAccountStep;
